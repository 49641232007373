<!-- <div class="fb-customerchat"
     page_id="112540307268274">
</div> -->


<!-- <livechat-widget #liveChatWidget licenseId="13019913" (onChatLoaded)="onChatLoaded($event)"></livechat-widget> -->



<!-- <livechat-widget #liveChatWidget licenseId="13019913" (onChatLoaded)="onChatLoaded($event)" 
(onPrechatSurveySubmitted)="onPrechatSurveySubmitted($event)"
(onChatWindowOpened)="onChatWindowOpened()" (onChatWindowMinimized)="onChatWindowMinimized()" 
[visitor]="visitor" [params]="params"></livechat-widget> -->

<router-outlet></router-outlet>


