<app-header></app-header>


<div class="backgroundBW content">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <div class="section1background">
    <div class="container section1ContainerPosition">
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-10 col-10">
          <h4 style="margin-bottom: 5%;">About Us</h4><br>
          <p style="color:white;">
            Formerly known as Dynaciate Group Berhad, we are now known as Ingenieur Gudang Berhad ("Ingenieur or Company").
            <br><br>
            Ingenieur and its subsidiaries ("Ingenieur Group" or "Group") are principally involved in the construction business
            where
            the Group provides civil, main mechanical, architectural, piping pre-fabrication and installation works and
            services. <br><br>
            The Group have recalibrated our strategic direction by pivoting into industrial and commercial warehousing
            sector to rebuild our sustainability that will better position the Group strategically and operationally
            moving forward. <br><br>
            This silver lining behind these dark clouds has been instrumental in lifting us out of the business
            doldrums.
          </p>
        </div>
      </div>
      <div class="row linksPosition" style="margin-top: 5%;">
        <div class="col-lg-2 col-md-2 col-sm-10 col-10 linksPosition">
          <a class="links" href="/about-us#ourTeam">
            <p><i>Our Team&nbsp;&nbsp;<span class="fa fa-angle-right hidden-sm hidden-md hidden-lg"></span></i></p>
          </a>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-10 col-10">
          <a class="links" href="/about-us#groupStructureChart">
            <p><i>Our Group Structure&nbsp;&nbsp;<span
                  class="fa fa-angle-right hidden-sm hidden-md hidden-lg"></span></i></p>
          </a>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-10 col-10">
          <a class="links" href="/about-us#corporateGovernance">
            <p><i>Corporate Governance&nbsp;&nbsp;<span
                  class="fa fa-angle-right hidden-sm hidden-md hidden-lg"></span></i></p>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div class="greybackground">
    <div class="container section2ContainerPosition">
      <!--LAPTOPS-->
      <div class="row hidden-xs">
        <div class="col-lg-9 col-md-8" style="padding-right: 2%; display: flex; align-items: center;">
          <p>The rationale for the name change represents the groups new symbolic vision and approach towards a
            contemporary representation of the Company’s nature of business and future undertakings.
            The word "Ingenieur" was adopted from a German word, pronounced engineer; whilst and Gudang, a Malay word
            for
            warehouse, showcasing an amalgamated philosophical representation towards efficiency and respect for our
            vernacular industrial design values.
            Committed to ensure the Group’s ambition towards a catalytic Gudang approach by actively seeking to secure
            industrial land and properties; then to model and be developed to their fullest potential, provisioned
            towards
            a new era of industrial investments.</p>
        </div>
        <div class="col-lg-3 col-md-4 center">
          <img src="./assets/image/Logo/FULL_LOGO_(TRANSPARENT).png" class="img-responsive" style="float:right;">
        </div>
      </div>
      <!--SMARTPHONES-->
      <div class="row hidden-sm hidden-md hidden-lg center">
        <div class="col-12 center">
          <img src="./assets/image/Logo/FULL_LOGO_(TRANSPARENT).png" class="img-responsive section2Logo"
            style="float:right; max-width: 50%;">
        </div>
        <div class="col-11 center section2Content" style="margin-bottom: 2%;">
          <p>The rationale for the name change represents the groups new symbolic vision and approach towards a
            contemporary representation of the Company’s nature of business and future undertakings.
            The word "Ingenieur" was adopted from a German word, pronounced engineer; whilst and Gudang, a Malay word
            for
            warehouse, showcasing an amalgamated philosophical representation towards efficiency and respect for our
            vernacular industrial design values.
            Committed to ensure the Group’s ambition towards a catalytic Gudang approach by actively seeking to secure
            industrial land and properties; then to model and be developed to their fullest potential, provisioned
            towards
            a new era of industrial investments.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="section" id="ourTeam">

    <div class="container col-11 section3ContainerPosition">
      <div class="row" style="margin-top: 6%;">
        <div class="col-lg-9 col-md-8">
          <h4 class="section3Title">Board of Directors</h4>
        </div>
      </div>
      <div class="row" style="padding-left: 15px; margin-top: 7%; margin-bottom: 3%;">
        <div class="col-lg-5 col-md-6 col-sm-5 col-11 border center section3Name">
          <p class="name">TAN SRI DATO' SERI MOHD KHAIRUL ADIB BIN ABD RAHMAN</p>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6 col-10 border center section3Jobtitle">
          <p class="jobtitle">Chairman</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">Tan Sri Dato Seri Mohd Khairul Adib was first appointed to the Board on 13 October
          2022 as an Independent Non-Executive Director and was redesignated as Chairman on 26 October 2022.
          Last served as the Director-General of Public Service
          before retiring from the Malaysian Civil Service in January 2022. Prior to that, he was the Secretary-General
          of the
          Ministry of Transport.<br><br>
          Tan Sri Dato Seri Mohd Khairul Adib began his career in the civil service in 1988 and has served various
          ministries
          over the period spanning 34 years including at the Ministry of Science, Technology and Environment, Ministry
          of
          Education and Malaysian missions in Tokyo and London. He has served on various Boards including Employee
          Provident
          Fund, Inland Revenue Board, Kumpulan Wang Persaraan (Diperbadankan), MAVCOM, Prasarana Malaysia Berhad,
          Keretapi Tanah
          Melayu Berhad, Malaysia Airports Holding Berhad, Konsortium Pelabuhan Kemaman Sdn Bhd, Malaysian Maritime
          Academy Sdn Bhd,
          Malaysia Rail Link Sdn Bhd, MyHSR Corporation Sdn Bhd, Prasarana Malaysia Berhad, Bintulu Port Authority, Port
          Klang Authority,
          Johor Port Authority, Pelabuhan Tanjung Pelepas Sdn Bhd, Railway Assets Corporation, Northern Gateway Sdn Bhd,
          Razak School
          of Government and Bukit Kayu Hitam Development Sdn Bhd.<br><br>
          In January 2022, Tan Sri Dato Seri Mohd Khairul Adib was conferred the Japanese Decoration, The Order of the
          Rising Sun,
          Gold and Silver Star by the Emperor of Japan.<br><br>
          He holds a Bachelor of Science (Hons.) and has a Postgraduate Diploma in Public Management. He also studied
          Public
          Policy from Saitama University, Japan under Japanese Government Scholarship. Tan Sri Dato Seri Khairul Adib
          bin Abd Rahman
          is currently the Chairman of Civil Aviation Authority of Malaysia (CAAM), Malaysian Qualifications Agency
          (MQA) and
          MMAG Holdings Berhad. He also sits on the Board of Westport Holdings Berhad.<br><br></p>
      </div>
    </div>

    <div class="container col-11 section3ContainerPosition">

      <div class="row" style="padding-left: 15px; margin-top: 5%; margin-bottom: 3%;">
        <div class="col-lg-2 col-md-2 col-sm-3 col-6 border center section3Name">
          <p class="name">CHIN BOON LONG</p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 col-7 border center section3Jobtitle">
          <p class="jobtitle">Executive Director</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">Born in Johor, Malaysia. Mr Chin holds a Master of Business Administration from
          Universiti Kebangsaan Malaysia and a Bachelor of Engineering (Electronic Computers) from Universiti Pertanian
          Malaysia.<br><br>
          Mr. Chin has over 20 years of vast experience in the ICT industry. He began his career in 1995 with an ICT
          company in Taiwan. He had held various positions in the company before he was promoted to the position of
          Sales Director in 2003. He acquired PC3 Technology Sdn. Bhd. (“PC3”) together with a business partner in 2004.
          Since then, he assumed the role as Managing Director of PC3 Technology and has led the company to become one
          of the leading authorised dealers of various international brands in Malaysia.<br><br>
          Since 2015 until present, he focuses entirely on expanding his personal investments portfolios through private
          companies which has spread out to various industries including ICT, constructions, manufacturing and property
          development, finance and others.<br><br>
          Currently, he is serving as an Executive Director in MMAG Holdings Berhad.<br><br></p>
      </div>
    </div>

    <div class="container col-11 section3ContainerPosition">
      <div class="row" style="padding-left: 15px; margin-top: 7%; margin-bottom: 3%;">
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 border center section3Name">
          <p class="name">SIN CHIN CHAI</p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-4 col-7 border center section3Jobtitle">
          <p class="jobtitle">Executive Director</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">
          Mr. Sin Chin Chai completed his Diploma in Management from the Malaysian Institute of Management in 2003,
          and he also graduated with first-class honors in Diploma in Electronic Engineering from
          the Federal Institute of Technology in 1995.<br><br>

          He embarked on his career journey in 1991 before he was subsequently appointed as the Branch Manager of a
          retail
          ICT company in 1996, where he assumed responsibility for the overall management and operation of the branch.
          This encompassed overseeing various aspects such as human resources, accounting, stock management, sales, and
          marketing.<br><br>

          In 1998, he took on the role of Branch Manager at another retail ICT company and later earned a promotion to
          become the
          Sales Director of renowned retail ICT player in 2014. In his capacity as Sales Director, he played a pivotal
          role in monitoring the progress of the Sales Division and ensuring the effective execution of tasks and
          duties.<br><br>

          In 2017, Mr. Sin took on the role of Sales Director at a subsidiary of an ICT public listed company. This
          opportunity
          allowed him to leverage his sales expertise within the technology sector.<br><br>

          Subsequently, in 2019, he established a private investment holding company. Under his guidance, this company
          has
          gradually extended its interests into sectors related to property investments and construction.
          Mr. Sin's leadership has been instrumental in nurturing the growth and diversification of the company's
          portfolio
          within these sectors, highlighting his adaptability and business acumen.<br><br>

          With over 27 years of dedicated professional experience in sales and operations, with a strategic focus on the
          property investments and construction sectors in recent years, Mr. Sin Chin Chai's current role as Director of
          his own business venture underscores his deep industry knowledge and diverse interests.<br><br></p>
      </div>
    </div>

    <div class="container col-11 section3ContainerPosition">
      <div class="row" style="padding-left: 15px; margin-top: 7%; margin-bottom: 3%;">
        <div class="col-lg-5 col-md-6 col-sm-5 col-11 border center section3Name">
          <p class="name">DATUK HJ ROSTAM AFFENDI BIN DATO’ HJ SALLEH</p>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6 col-10 border center section3Jobtitle">
          <p class="jobtitle">Independent Non-Executive Director</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">Datuk Hj Rostam Affendi bin Dato’ Hj Salleh boasts an impressive blend of
          educational
          accomplishments and a robust career trajectory within the Malaysian public service sector, culminating in his
          role as the Director General of the Research Division at the Prime Minister's
          Department prior to his retirement in January 2023.<br><br>
          His educational journey is marked by a Bachelor of Science in Civil Engineering from the University of North
          Carolina at Charlotte in 1986, a Diploma in Public Administration from Public Administration Institute
          Malaysia, in 1989, and a Master's in Strategic Studies from the National University of Malaysia in
          2002.<br><br>
          He began his career in 1989 within the Malaysian government’s Administrative and Diplomatic Service, quickly
          advancing to roles of increasing responsibility. Notable positions include First Secretary at the Malaysian
          Representative Office to the United Nations in Geneva (1993), Head of the Technical Unit in the Prime
          Minister's Department (1997), Minister Counselor at the Malaysian High Commission in the UK (2005-2009), and
          later as Strategic Director and then Director-General of the Special Strategic Unit in the Prime Minister’s
          Department.<br><br>
          Datuk Hj. Rostam’s tenure saw him serving under three prime ministers, highlighting his adaptability and
          leadership in various capacities, including a pivotal role as Deputy Secretary-General (Consumer Affairs and
          Management) at the Ministry of Domestic Trade and Consumer Affairs. His work at the ministry involved
          significant contributions as a Board Director for Intellectual Property of Malaysia (MyIPO) and in engagements
          with the Companies Commission of Malaysia (SSM) and the Malaysia Competition Commission (MyCC).<br><br>
          Recognised with multiple excellence awards and prestigious national honors for his contributions to Malaysia's
          development, Datuk Hj. Rostam's vast experience in strategic affairs, international diplomacy, and public
          policy makes him an exemplary candidate for directorial roles, offering valuable leadership and
          insight.<br><br>
        </p>
      </div>
    </div>

    <div class="container col-11 section3ContainerPosition">
      <div class="row" style="padding-left: 15px; margin-top: 7%; margin-bottom: 3%;">
        <div class="col-lg-2 col-md-3 col-sm-4 col-5 border center section3Name">
          <p class="name">TAN SIEW PENG</p>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6 col-10 border center section3Jobtitle">
          <p class="jobtitle">Independent Non-Executive Director</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">Ms Tan was appointed to the Board as Independent Non-Executive Director on 8 March
          2019.
          She is also the Chairman of the Audit Committee as well as a member of the Nomination and Remuneration
          Committees.<br><br>
          She is professionally qualified from The Association of Chartered Certified Accountants (ACCA), United
          Kingdom.
          Ms Tan also holds a Diploma in Commerce (Financial Accounting) from Tunku Abdul Rahman College (now known as
          Tunku Abdul Rahman University College), Malaysia and is a member of the Malaysian Institute of Accountants
          (MIA).<br><br>
          Ms Tan has over 25 years’ experience in the areas of financial management, treasury, tax planning and
          compliance
          as well as enterprise risk management. Ms Tan started her career with Price Waterhouse Johor Bahru in 1994
          before transiting into the commercial sector where she has held senior positions in companies involved in the
          electronics, construction and property development activities. She is presently the Assistant Director of
          Finance at United Malayan Land Bhd (UMLand) where she is in charge of the Finance Division of UMLand’s
          townships
          development in the southern region.<br><br></p>
      </div>
    </div>

    <div class="container col-11 section3ContainerPosition">
      <div class="row" style="padding-left: 15px; margin-top: 7%; margin-bottom: 3%;">
        <div class="col-lg-3 col-md-3 col-sm-4 col-5 border center section3Name">
          <p class="name">DATUK RUZAIN BIN IDRIS</p>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6 col-10 border center section3Jobtitle">
          <p class="jobtitle">Independent Non-Executive Director</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">Datuk Ruzain Bin Idris last served as the Deputy Secretary General (Planning &
          Development) in the Ministry of Federal Territories prior to his retirement in October 2022.
          <br><br>
          He began his career in the civil service in 1988 and has served various ministries over the period spanning 34
          years including the Ministry of Information, Public Service Department, Ministry of Transport, Ministry of
          Communications and Multimedia amongst others.
          <br><br>
          Datuk Ruzain specialises in diverse fields which include but not limited to environmental management; spatial
          and urbanisation management; organisational and human resources management; strategic management planning;
          risk management and consultation; administrative and financial management as well as project
          management/development.
          <br><br>
          He had also served as a member of Perbadanan Putrajaya from 2016 until 2018 and appointed as a Board Member
          of BERNAMA from January 2021 until January 2022.
          <br><br>
          He obtained a Bachelor of Arts, majoring in Geography from Universiti Malaya in 1985, followed by a Diploma in
          Public Administration from Institut Tadbiran Awam Negara (INTAN) in 1987. Thereafter, in year 1994, he
          completed his Master’s in Business Administration (International Business) from University of Birmingham,
          United Kingdom.
        </p>
      </div>
    </div>

    <div class="container col-11 section3ContainerPosition">
      <div class="row" style="padding-left: 15px; margin-top: 7%; margin-bottom: 3%;">
        <div class="col-lg-2 col-md-3 col-sm-4 col-6 border center section3Name">
          <p class="name">CHAN SWEE YING</p>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6 col-10 border center section3Jobtitle">
          <p class="jobtitle">Alternate Director to Mr. Chin Boon Long</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">Mdm Chan Swee Ying holds a Degree in Computer Science from University Putra
          Malaysia.<br><br>
          She has more than 20 years of working experience and a strong background in the Information Technology (“IT”)
          industry. She started her career with Oracle Malaysia in 1996 before moving on to DHL Asia Pacific IT
          department managing both local and regional projects in year 2005. She also assisted in developing business
          plans to secure financial support from the banking institutions for the company’s growth.<br><br>
          Ms Chan is also well experienced in the Human Resources field, having assumed the role of General Manager from
          2010 to 2015 at an ICT group of companies.<br><br>
          With her management skills and competency in operational efficiency and resources administration, she has
          helped the Company increase productivity while maintaining costs and expenses especially during the economy
          slowdown.<br><br>
          Currently, she is also an Alternate Director to Mr. Chin Boon Long in MMAG Holdings Berhad (“MMAG”).<br><br>
        </p>
      </div>
    </div>

    <div class="container col-11 section3ContainerPosition">
      <div class="row" style="padding-left: 15px; margin-top: 7%; margin-bottom: 3%;">
        <div class="col-lg-3 col-md-3 col-sm-4 col-5 border center section3Name">
          <p class="name">YEE WAI MENG, BERNARD</p>
        </div>
        <div class="col-lg-4 col-md-5 col-sm-6 col-10 border center section3Jobtitle">
          <p class="jobtitle">Chief Financial Officer</p>
        </div>
      </div>
      <div class="row col-lg-12 col-md-12">
        <p style="padding-left: 0%;">Mr. Bernard obtained his professional Degree in Finance and Accounting from
          the Association of International Accountant (AIA), UK, in 2002. Additionally, in 2006 and 2007,
          Mr. Bernard completed a comprehensive Management Development Programme, further enhancing his expertise.
          <br><br>
          He began his career journey as an Accounts Officer in 1989, demonstrating early dedication and commitment
          to the field of finance and accounting. Over the years, he has honed his skills and held progressively
          challenging roles such as Internal Auditor, Finance Manager, Financial Controller, Chief Financial Officer
          and Director of Finance in companies of varying scales and industries, from local startups, public-listed
          and multinational corporations. His remarkable career spans more than two decades, encompassing expertise
          in accounting, finance, auditing, taxation, budgeting, forecasting, financial and management analysis,
          and treasury management.
          <br><br>
          Mr. Bernard last served as the Chief Financial Officer (CFO) of a local start-up software firm,
          where he plays a pivotal role in shaping the financial strategies and direction of the company,
          contributing to its growth and success in a highly competitive industry.
          <br><br>
          Throughout his career, Mr. Bernard has exhibited exceptional proficiency in implementing robust
          accounting policies and procedures, ensuring sound internal controls and compliance with industry standards.
          Furthermore, he possesses a wealth of knowledge in corporate secretarial matters, corporate affairs and
          exercises, office administration, and corporate finance and planning.
        </p>
      </div>
    </div>

  </div>

  <div class="greybackground">
    <div class="center">
      <h4 class="section4Title" style="color: white;">Management Team</h4>
    </div>
    <div class="container col-11 center section4ContainerPosition" style="padding-bottom: 4%;">
      <!-- <div class="row col-lg-5 col-md-5 col-12 section4table" style="margin-right: 0%;">
        <div class="col-lg-12 col-md-12 col-6 border section4Name">
          <p style="margin: 1% 1%">TAN OOI JIN</p>
        </div>
        <div class="col-lg-12 col-md-12 col-6 border">
          <p style="margin: 2% 2%; color: white;">Executive Director</p>
        </div>
      </div> -->
      <div class="row col-lg-5 col-md-5 col-12 section4table" style="margin: 0%;">
        <div class="col-lg-12 col-md-12 col-6 border section4Name">
          <p style="margin: 1% 1%">SIN CHIN CHAI</p>
        </div>
        <div class="col-lg-12 col-md-12 col-6 border">
          <p style="margin: 2% 2%; color: white;">Executive Director</p>
        </div>
      </div>
      <div class="row col-lg-4 col-md-5 col-12 section4table" style="margin: 0%;">
        <div class="col-lg-12 col-md-12 col-6 border section4Name">
          <p style="margin: 1% 1%">YEE WAI MENG, BERNARD</p>
        </div>
        <div class="col-lg-12 col-md-12 col-6 border">
          <p style="margin: 2% 2%; color: white;">Chief Financial Officer</p>
        </div>
      </div>
    </div>
  </div>

  <div class="section" id="groupStructureChart">
    <div class="center">
      <h4 class="section5Title" style="color:white;">Group Structure</h4>
    </div>
    <div class="center" style="margin-bottom: 5%;">
      <img src="./assets/image/Component/P2-GROUP_STRUCTURE(DESKTOP).png"
        class="img-responsive center hidden-xs hidden-sm section5Image" style="max-width: 53%;">
      <img src="./assets/image/Component/P2-GROUP_STRUCTURE(MOBILE).png"
        class="img-responsive center hidden-md hidden-lg section5Image" style="max-width: 55%;">
    </div>
  </div>

  <div id="corporateGovernance" class="section section6background">
    <div class="container section6ContainerPosition">
      <div class="center">
        <h4 class="section6Title" style="color:white; margin-top:7%; margin-bottom: 6%;">Corporate Governance</h4>
      </div>
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4 col-sm-6 col-8 mx-auto" style="margin-bottom:20px;">
            <div class="box center">
              <a href="./doc/corporate/Board_Charter.pdf" target="_blank">
                <h5>Board Charter</h5>
              </a>

            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-8 mx-auto" style="margin-bottom:20px;">
            <div class="box center">
              <div class="row col-12">
                <h5 style="margin-top: 10%; margin-bottom: 10%;">Term of Reference</h5><br>
                <ul style="margin-left: 6%;">
                  <li><a href="./doc/corporate/Audit_Committee.pdf" target="_blank">
                      <h5 style="color:white; margin-bottom: 0%; text-align: left;">Audit Committee</h5>
                    </a></li>
                  <li><a href="./doc/corporate/Remuneration_Committee.pdf" target="_blank">
                      <h5 style="color:white; margin-top: 5%;  margin-bottom: 0%; text-align: left;">Remuneration
                        Committee</h5>
                    </a></li>
                  <li><a href="./doc/corporate/Nomination_Committee.pdf" target="_blank">
                      <h5 style="color:white; margin-top: 5%; text-align: left;">Nomination Committee</h5>
                    </a></li>
                  <!--New-->
                  <li><a href="./doc/corporate/Risk_Management_Committee.pdf" target="_blank">
                      <h5 style="color:white; margin-top: 5%; text-align: left;">Risk Management Committee</h5>
                    </a></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-8 mx-auto mb-4" style="margin-bottom:20px;">
            <div class="box center">
              <a href="./doc/corporate/Code_of_Conduct_Ethics.pdf" target="_blank">
                <h5>Code of Conduct & Ethics</h5>
              </a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-8 mx-auto " style="margin-bottom:20px;">
            <div class="box center">
              <a href="./doc/corporate/Whistleblowing_Policy.pdf" target="_blank">
                <h5>Whistleblowing Policy</h5>
              </a>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-8 mx-auto" style="margin-bottom:20px;">
            <div class="box center">
              <a href="./doc/corporate/Anti-Bribery_ Corruption_Policy.pdf" target="_blank">
                <h5>Anti-Bribery & Corruption Policy</h5>
              </a>
            </div>
          </div>
          <div class="col-md-4 col-sm-6 col-8 mx-auto" style="margin-bottom:10px;">
            <div class="box center">
              <a href="./doc/corporate/Fit_and_Proper_Policy.pdf" target="_blank">
                <h5>Fit and Proper Policy</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button *ngIf="isShow" class="back-to-top btn  btn-lg" (click)="scrollToTop()"> <i class="fas fa-angle-double-up" style="color: rgb(255, 255, 255);"></i> </button>
  
</div>



<app-footer></app-footer>